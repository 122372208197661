.Home_screen {
  background-image: url("/images/lionel-hesry-xWMjA8WhjpA-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  position: relative;
}
.overlay {
  background-color: black;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  opacity: 0.6;
  position: absolute;
  top: 0px;
  left: 0px;
}

body {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-family: "Oswald", sans-serif;
  font-size: 49px;
  margin: 5px 0px 15px 0px;
  letter-spacing: 3px;
}
h4 {
  font-family: "Oswald", sans-serif;
  font-size: 26px;
  margin: 15px 0px 0px 0px;
  font-weight: 300;
}
.button {
  border: 2px;
  border-radius: 50px;
  color: white;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  padding: 20px 45px 20px 45px;
  margin-top: 50px;
  text-transform: uppercase;
  transition: 0.3s;
  font-family: "Oswald", sans-serif;
}
.button:hover {
  color: white;
  background-color: #e65f78;
  border-color: #e65f78;
  pointer-events: visible;
}
